<div class="flex flex-row items-center justify-between">

  <div class="flex flex-row items-center">
    <div class="mr-2" *ngIf="data.showIcon">

      <div>

        <mat-icon class="text-white" *ngIf="data.type === 'info'" [svgIcon]="'heroicons_solid:information-circle'">
        </mat-icon>

        <mat-icon class="text-white" *ngIf="data.type === 'success'" [svgIcon]="'heroicons_solid:check-circle'">
        </mat-icon>

        <mat-icon class="text-white" *ngIf="data.type === 'warning'" [svgIcon]="'heroicons_solid:exclamation'">
        </mat-icon>

        <mat-icon class="text-white" *ngIf="data.type === 'error'" [svgIcon]="'heroicons_solid:x-circle'"></mat-icon>

      </div>

    </div>

    <!-- Content -->
    <div class="flex flex-col">
      <div class="text-lg font-bold">{{ data.title }}</div>
      <div>{{ data.message }}</div>
    </div>
  </div>

  <!-- Dismiss button -->
  <button mat-icon-button (click)="dismiss()">
    <mat-icon class="text-white" [svgIcon]="'heroicons_solid:x'"></mat-icon>
  </button>

</div>
