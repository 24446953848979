import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingBarService {
  private _ignore = new BehaviorSubject<boolean>(false);

  constructor() {}

  get ignore$(): Observable<boolean> {
    return this._ignore.asObservable();
  }

  set ignore(value: any) {
    this._ignore.next(value);
  }
}
