import { Component, Input, OnInit } from '@angular/core';
import { SelectSindicatoService } from './select-sindicato.service';
import { AuthService } from 'app/core/auth/auth.service';
import { take } from 'rxjs';
import { DominioService } from '../dominio.service';

@Component({
  selector: 'app-select-sindicato',
  templateUrl: './select-sindicato.component.html',
  styleUrls: ['./select-sindicato.component.scss'],
})
export class SelectSindicatoComponent implements OnInit {
  constructor(
    private _auth: AuthService,
    private _dominio: DominioService,
    private _service: SelectSindicatoService
  ) {}

  @Input() color: String;

  user: any;
  resetList: any;
  municipio: any;
  sindicatos: any;
  selecionado: any;
  textoSindicato = '';
  textoMunicipio = '';
  blockSindicatos = false;
  filteredMunicipio: any = [];

  initi = false;
  ngOnInit() {
    this.user = this._auth.userValue;
    this.blockSindicatos = this._dominio.getBlockSindicatos;

    this._service?.sindicatos$.subscribe((sindicatos) => {
      if (sindicatos) {
        this.resetList = sindicatos;
        this.sindicatos = sindicatos;

        if (!this.initi) {
          this.initi = true;

          const selected = localStorage.getItem('selectedSindicato');

          if (selected) {
            this.setSindicato(Number(selected));
          } else {
            this.setSindicato('');
          }
        }
      }
    });

    this._service?.sindicatoSelecionado$.subscribe((selected) => {
      if (selected) {
        this.selecionado = selected;
      }
    });

    if (this.user?.admin || this.user?.gerenciaSindical) {
      this.setSindicato('');
    }

    this._service.municipios$.subscribe((municipio) => {
      if (municipio) {
        this.municipio = municipio;
        this.filteredMunicipio = municipio;
      } else {
        this.municipio = [];
      }
    });

    if (this.blockSindicatos) {
      this._service.getMunicipio(null).pipe(take(1)).subscribe({});
    }
  }

  getGrupoFiliacao(text) {
    this.sindicatos = this.resetList?.filter((option) => {
      return option?.nome?.toUpperCase().includes(text?.toUpperCase());
    });
  }

  setSindicato(value: any) {
    const selected = this.sindicatos?.find((res) => res?.id === value);

    if (value === 'TODOS') {
      this.textoSindicato = 'TODOS';

      this._service.getMunicipio(null).pipe(take(1)).subscribe({});
    }

    if (value && selected) {
      this.textoSindicato = selected?.nome;
      this.textoMunicipio = 'TODOS';
      this._service.sindicatoSelecionado = selected;
      this._service.getMunicipio(value).pipe(take(1)).subscribe({});

      const sindicato = localStorage.setItem('selectedSindicato', value);
    } else if (this.textoSindicato === 'TODOS') {
      this.textoMunicipio = 'TODOS';
      this._service.sindicatoSelecionado = { id: '', nome: 'TODOS' };
    } else if (
      (!selected && this.user?.admin) ||
      (!selected && this.user?.gerenciaSindical)
    ) {
      this.setSindicato('TODOS');
    } else if (!selected && this.resetList?.length) {
      this.setSindicato(this.resetList[0]?.id);
    }
  }

  getMunicipio(text) {
    this.filteredMunicipio = [];

    this.municipio.filter((option) => {
      if (option.descricao.toUpperCase().includes(text.toUpperCase())) {
        this.filteredMunicipio.push(option);
      }
    });
  }

  setMunicipio(municipio) {
    if (municipio === 'TODOS') {
      this.textoMunicipio = 'TODOS';
      this._service.municipiosSelecionado = { id: '', nome: 'TODOS' };
    } else {
      this._service.municipiosSelecionado = municipio;
      this.textoMunicipio = `${municipio.descricao} - ${municipio.uf}`;
    }
  }
}
