import moment from 'moment';

export class Campanha {
  public id: number;
  public nome: string;
  public telefoneOrigem: string;
  public mensagem: {
    id: number;
    nome: string;
  };
  public criadoEm: Date;
  public enviadoEm: Date;
  public naoEnviar: boolean;
  public contabilizarDash: boolean;
  public ativo: boolean;
  public objetivo: string;
  public tipo: string;
  public perguntas: any;
  public inicioCurso: Date;
  public fimCurso: Date;
  public tipoVinculo: String;

  constructor(campanha?: Campanha) {
    if (campanha) {
      this.id = campanha.id;
      this.nome = campanha.nome;
      this.telefoneOrigem = campanha.telefoneOrigem;
      this.mensagem = campanha.mensagem;
      this.criadoEm = campanha.criadoEm
        ? new Date(
            campanha.criadoEm.toString().replace(/-/g, '/').replace(/T.+/, '')
          )
        : null;
      this.enviadoEm = campanha.enviadoEm
        ? new Date(
            campanha.enviadoEm.toString().replace(/-/g, '/').replace(/T.+/, '')
          )
        : null;
      this.naoEnviar = campanha.naoEnviar;
      this.contabilizarDash = campanha.contabilizarDash;
      this.objetivo = campanha.objetivo;
      this.tipo = campanha.tipo;
      this.ativo = campanha.ativo;
      this.perguntas = campanha.perguntas;
      this.tipoVinculo = campanha.tipoVinculo;
      this.fimCurso = moment(campanha.fimCurso).toDate();
      this.inicioCurso = moment(campanha.inicioCurso).toDate();
    } else {
      this.id = null;
      this.nome = null;
      this.telefoneOrigem = null;
      this.mensagem = null;
      this.criadoEm = null;
      this.enviadoEm = null;
      this.naoEnviar = null;
      this.contabilizarDash = null;
      this.tipo = null;
      this.ativo = null;
      this.fimCurso = null;
      this.inicioCurso = null;
      this.tipoVinculo = null;
    }
  }
}
