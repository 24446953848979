import { MatSnackBar } from '@angular/material/snack-bar';
import { toast } from 'app/core/utils/utils';
import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { Component, ElementRef, HostBinding, HostListener, NgZone, OnDestroy, OnInit, Renderer2, ViewChild, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';

import { Subject, takeUntil } from 'rxjs';
import { QuickChatService } from 'app/layout/common/quick-chat/quick-chat.service';
import { Chat } from 'app/layout/common/quick-chat/quick-chat.types';
import firebase from 'firebase';
import { ConversaMensagem } from './../../../model/conversa-mensagem';
import { ChatService } from 'app/modules/crm/gestao-campanha/chat/chat.service';

@Component({
  selector: 'quick-chat',
  templateUrl: './quick-chat.component.html',
  styleUrls: ['./quick-chat.component.scss'],
  encapsulation: ViewEncapsulation.None,
  exportAs: 'quickChat'
})
export class QuickChatComponent implements OnInit, OnDestroy {
  @ViewChild('messageInput') messageInput: ElementRef;
  chat: Chat;
  chats: Chat[];
  opened: boolean = false;
  selectedChat: Chat;
  private _scrollStrategy: ScrollStrategy = this._scrollStrategyOptions.block();
  private _overlay: HTMLElement;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  conversas = [];
  mensagens = [];
  conversaSelected;
  mon = moment;
  newMessage: ConversaMensagem = new ConversaMensagem();
  conversaMensagemClasseIdSelected = null;
  conversaMensagemClasse = [];

  /**
   * Constructor
   */
  constructor(
    private _elementRef: ElementRef,
    private _renderer2: Renderer2,
    private _ngZone: NgZone,
    private _quickChatService: QuickChatService,
    private _scrollStrategyOptions: ScrollStrategyOptions,
    private _chatService: ChatService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _snackBar: MatSnackBar
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Decorated methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Host binding for component classes
   */
  @HostBinding('class') get classList(): any {
    return {
      'quick-chat-opened': this.opened
    };
  }

  /**
   * Resize on 'input' and 'ngModelChange' events
   *
   * @private
   */
  @HostListener('input')
  @HostListener('ngModelChange')
  private _resizeMessageInput(): void {
    // This doesn't need to trigger Angular's change detection by itself
    this._ngZone.runOutsideAngular(() => {

      setTimeout(() => {

        // Set the height to 'auto' so we can correctly read the scrollHeight
        this.messageInput.nativeElement.style.height = 'auto';

        // Get the scrollHeight and subtract the vertical padding
        this.messageInput.nativeElement.style.height = `${this.messageInput.nativeElement.scrollHeight}px`;
      });
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // this.novasMensagens();

    // const messaging = firebase.messaging();

    // messaging.onMessage((payload) => {
    //   this.novasMensagens();
    // });

    // Chats
    this._chatService.conversa$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((conversas: any) => {
        this.conversas = conversas;

        // Mark for check
        // this._changeDetectorRef.markForCheck();
      });

    this._chatService.mensagens$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((mensagens: any) => {
        // Chat
        this._chatService.conversa$
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe(async (conversa: any) => {
            if (mensagens) {
              this.conversaSelected = conversa.find(e => e.id == mensagens.id);
              if (this.conversaSelected) {
                this.mensagens = mensagens.messages;
              } else {
                this.mensagens = null;
              }
            } else {
              this.mensagens = null;
            }

            if (this.conversaSelected && this.conversaSelected.novasMensagens && mensagens) {
              await this._chatService.setMensagensLidas({
                id: parseInt(mensagens.id),
                mensagensLidas: this.conversaSelected.novasMensagens,
              });
              // this._chatService.getConversa().subscribe(() => { });
              this._chatService.conversa$
                .pipe(takeUntil(this._unsubscribeAll))
                .subscribe((conversa: any) => {
                  const conversas = conversa;
                  conversas.map(c => {
                    if (c.id === this.conversaSelected.id && c.novasMensagens) {
                      c.novasMensagens = 0;
                      this._chatService.setConversa(conversas);
                    }
                  })
                });
            }

            // Mark for check
            // this._changeDetectorRef.markForCheck();
          });
      });

    this._chatService.conversaMensagemClasse$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((conversaMensagemClasse) => {
        this.conversaMensagemClasse = conversaMensagemClasse;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Open the panel
   */
  open(): void {
    // Return if the panel has already opened
    if (this.opened) {
      return;
    }

    // Open the panel
    this._toggleOpened(true);
  }

  /**
   * Close the panel
   */
  close(): void {
    // Return if the panel has already closed
    if (!this.opened) {
      return;
    }

    // Close the panel
    this._toggleOpened(false);
  }

  /**
   * Toggle the panel
   */
  toggle(): void {
    if (this.opened) {
      this.close();
    }
    else {
      this.open();
    }
  }

  /**
   * Select the chat
   *
   * @param id
   */
  selectChat(id: string): void {
    // Open the panel
    this._toggleOpened(true);

    const conversaSelected = this.conversas.find(conversa => conversa.id == id);

    // Get the chat data
    this._chatService.getMensagens(id).subscribe(() => {
      this.newMessage = new ConversaMensagem();
      this.conversaMensagemClasseIdSelected = null;
      this.conversaSelected = conversaSelected;
    });
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Show the backdrop
   *
   * @private
   */
  private _showOverlay(): void {
    // Try hiding the overlay in case there is one already opened
    this._hideOverlay();

    // Create the backdrop element
    this._overlay = this._renderer2.createElement('div');

    // Return if overlay couldn't be create for some reason
    if (!this._overlay) {
      return;
    }

    // Add a class to the backdrop element
    this._overlay.classList.add('quick-chat-overlay');

    // Append the backdrop to the parent of the panel
    this._renderer2.appendChild(this._elementRef.nativeElement.parentElement, this._overlay);

    // Enable block scroll strategy
    this._scrollStrategy.enable();

    // Add an event listener to the overlay
    this._overlay.addEventListener('click', () => {
      this.close();
    });
  }

  /**
   * Hide the backdrop
   *
   * @private
   */
  private _hideOverlay(): void {
    if (!this._overlay) {
      return;
    }

    // If the backdrop still exists...
    if (this._overlay) {
      // Remove the backdrop
      this._overlay.parentNode.removeChild(this._overlay);
      this._overlay = null;
    }

    // Disable block scroll strategy
    this._scrollStrategy.disable();
  }

  /**
   * Open/close the panel
   *
   * @param open
   * @private
   */
  private _toggleOpened(open: boolean): void {
    // Set the opened
    this.opened = open;

    // If the panel opens, show the overlay
    if (open) {
      this._showOverlay();
    }
    // Otherwise, hide the overlay
    else {
      this._hideOverlay();
    }
  }

  novasMensagens() {
    this._chatService.novasMensagens()
      .then(messages => {
        messages.map(message => {
          if (message && this.selectedChat && message.id == this.selectedChat.id) {
            this._chatService.getMensagens(message.id)
              .subscribe(() => { })
          } else {
            const index = this.conversas.findIndex(e => e.id == message.id);
            if (index > -1) {
              this.conversas[index].novasMensagens = message.novasMensagens;
              const conversaIndex = this.conversas.findIndex(e => e.id == message.id);
              if (conversaIndex > -1) {
                this.conversas[conversaIndex].novasMensagens = message.novasMensagens;
              }
            }
          }
        });
        // Mark for check
        this._changeDetectorRef.markForCheck();
      })
      .catch(err => console.log(err));
  }

  resposta(e) {
    if (e) {
      this.newMessage.mensagem = e.textoResposta;
      this.conversaMensagemClasseIdSelected = e.id;
    }
    this.newMessage.conversa = e.conversa.id;
  }

  sendMessage() {
    this.newMessage.para = this.mensagens.filter(i => i.de != this.conversaSelected.para)[0].de;
    const nome = localStorage.getItem("nome");
    this.newMessage.mensagem = nome ? `${nome}\n\n${this.newMessage.mensagem}` : this.newMessage.mensagem;
    this._chatService.enviarMensagem(this.conversaSelected.id, this.newMessage).then(async r => {
      try {
        if (this.conversaMensagemClasseIdSelected) {
          await this._chatService.salvarContato({ classe_id: this.conversaMensagemClasseIdSelected, id: this.newMessage.conversa });
        }
      } catch (err) {
        console.log(err);
      }
      this.newMessage = new ConversaMensagem();
      this.conversaSelected.ultimoContato = r.conversa.ultimoContato;
      this._chatService.id$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(id => {
          this._chatService.getMensagens(id)
            .subscribe(() => { })
        })
    }).catch(err => {
      console.log(err);
      toast(this._snackBar, 'Ops!', 'Ocorreu um erro', 'error', true, 5000);
    });
  }
}
