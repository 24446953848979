import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { DominioService } from 'app/shared/dominio.service';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DominioGuard implements CanActivate {
  constructor(private router: Router, private _dominio: DominioService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this._dominio.getRotaInicial) {
      console.log(environment.api);

      throw new Error(
        '=============== Sem Rota inicial determinada para esse host'
      );
    }

    if (this.isHome(this._dominio.getRotaInicial)) {
      return true; // Permite o acesso ao módulo
    }

    // Caso contrário, redireciona o usuário para uma página de acesso negado ou outra rota adequada
    this.router.navigateByUrl(this._dominio.getRotaInicial);
    return false; // Bloqueia o acesso ao módulo
  }

  private isHome(rota: string): Boolean {
    if (rota === 'home') {
      return true;
    } else {
      return false;
    }
  }
}
