import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TextMaskModule } from 'angular2-text-mask';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { DateComponent } from './date/date.component';
import { NgxMaskModule } from 'ngx-mask';
import { AsidePanelModule } from 'app/modules/auth/aside-panel/aside-panel.module';
import { SelectMunicipioComponent } from './select-municipio/select-municipio.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SelectSindicatoModule } from './select-sindicato/select-sindicato.module';
import { TabelaPadraoComponent } from './tabela-padrao/tabela-padrao.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    TextMaskModule,
    MatFormFieldModule,
    MatInputModule,
    AsidePanelModule,
    MatAutocompleteModule,
    NgxMaskModule.forRoot(),
    SelectSindicatoModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DateComponent,
    AsidePanelModule,
    NgxMaskModule,
    SelectMunicipioComponent,
    SelectSindicatoModule,
  ],
  declarations: [DateComponent, SelectMunicipioComponent],
})
export class SharedModule {}
