import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, tap, of, catchError, take } from 'rxjs';

import * as moment from 'moment';
import { environment } from './../../../../environments/environment';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private _ultimosAtualizados: BehaviorSubject<any> = new BehaviorSubject([]);
  private _aniversariantesHoje: BehaviorSubject<any> = new BehaviorSubject([]);
  private _tipoDirigente: BehaviorSubject<any> = new BehaviorSubject([]);
  private _atendimento: BehaviorSubject<any> = new BehaviorSubject([]);

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient, private _auth: AuthService) {}

  get ultimosAtualizados$(): Observable<any> {
    return this._ultimosAtualizados.asObservable();
  }

  get aniversariantesHoje$(): Observable<any> {
    return this._aniversariantesHoje.asObservable();
  }

  get tipoDirigente$(): Observable<any> {
    return this._tipoDirigente.asObservable();
  }

  get atendimento$(): Observable<any> {
    return this._atendimento.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getUltimosAtualizados(grupoFiliacaoId, municipioId): Observable<any> {
    return this._httpClient
      .post(`${environment.api}/pessoa/ultimosAtualizados`, {
        login: this._auth?.userValue?.login,
        grupoFiliacaoId: Number(grupoFiliacaoId)
          ? Number(grupoFiliacaoId)
          : null,
        municipioId: Number(municipioId) ? Number(municipioId) : null,
      })
      .pipe(
        catchError((error) => {
          return of(this._ultimosAtualizados.next([]));
        }),
        tap((response: any) => {
          this._ultimosAtualizados.next(response);
        }),
        take(1)
      );
  }

  getAniversariantesHoje(grupoFiliacaoId, municipioId): Observable<any> {
    return this._httpClient
      .post(
        `${environment.api}/dirigente/todaysBirthdays/${moment().format(
          'YYYY-MM-DD'
        )}`,
        {
          login: this._auth?.userValue?.login,
          grupoFiliacaoId: Number(grupoFiliacaoId)
            ? Number(grupoFiliacaoId)
            : null,
          municipioId: Number(municipioId) ? Number(municipioId) : null,
        }
      )
      .pipe(
        catchError((error) => {
          throw error;
        }),
        tap((response: any) => {
          this._aniversariantesHoje.next(response);
        })
      );
  }

  getAtendimentosOpen(login): Observable<any> {
    return this._httpClient
      .get(`${environment.api}/atendimento/getOpen/${login}`)
      .pipe(
        catchError((error) => {
          return of(this._atendimento.next([]));
        }),
        tap((response: any) => {
          this._atendimento.next(response);
        })
      );
  }

  validarPrimeiroAcesso(change): Observable<any> {
    return this._httpClient
      .post(`${environment.api}/usuario/validarPrimeiroAcesso`, change)
      .pipe(
        catchError((error) => {
          throw error;
        })
      );
  }

  limparCache(): Observable<any> {
    return this._httpClient
      .get(`${environment.api}/dashboard/limparCache`)
      .pipe(
        catchError((error) => {
          throw error;
        }),
        tap({})
      );
  }
}
