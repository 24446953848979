import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, tap, catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IframeCotacoesService {
  private _cotacoes: BehaviorSubject<any> = new BehaviorSubject([]);
  private _modo: BehaviorSubject<string> = new BehaviorSubject('');
  private _cota: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(private _httpClient: HttpClient) {}

  get cotacoes$(): Observable<any> {
    return this._cotacoes.asObservable();
  }

  set cotacoes$(value: any) {
    this._cotacoes.next(value);
  }

  get modo$(): Observable<any> {
    return this._modo.asObservable();
  }

  get cota$(): Observable<any> {
    return this._cota.asObservable();
  }

  set cota$(value: any) {
    this._cota.next(value);
  }

  getCotacoes(modo: string, cota: string, multi?: Boolean): Observable<any> {
    this._modo.next(modo);

    if (!multi) {
      this._cota.next(cota);
    }

    return this._httpClient
      .get(`https://api.leitebem.com.br/cotacoes/${cota}`)
      .pipe(
        catchError((error) => {
          console.log(error);

          return of(this._cotacoes.next([]));
        }),
        tap((items: any) => {
          if (!multi) {
            return of(
              this._cotacoes.next(
                items?.filter((c) => c?.status === 'approved')
              )
            );
          } else {
            return of(items?.filter((c) => c?.status === 'approved'));
          }
        })
      );
  }
}
