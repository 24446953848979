import { Pessoa } from './pessoa';
import { Campanha } from './campanha';


export class Conversa {

    id: number;
    campanha: Campanha;
    pessoa: Pessoa;
    dataInicio: Date;
    ultimoContato: Date;
    ativa: boolean;
    para: String;
    responder: boolean;

    constructor(conversa?: Conversa) {
        if (conversa) {
            this.id = conversa.id;
            this.campanha = new Campanha(conversa.campanha);
            this.pessoa = new Pessoa(conversa.pessoa);
            this.dataInicio = conversa.dataInicio ? new Date(conversa.dataInicio.toString().replace(/-/g, '\/').replace(/T.+/, '')) : null;
            this.ultimoContato = conversa.ultimoContato ? new Date(conversa.ultimoContato.toString().replace(/-/g, '\/').replace(/T.+/, '')) : null;
            this.ativa = conversa.ativa;
            this.para = conversa.para;
            this.responder = conversa.responder;
        } else {
            this.id = null;
            this.campanha = null;
            this.pessoa = null;
            this.dataInicio = null;
            this.ultimoContato = null;
            this.ativa = true;
            this.para = '';
            this.responder = false;
        }
    }

}