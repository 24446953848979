import { first } from 'rxjs';
import * as moment from 'moment';
import { ChangeDetectorRef, Component } from '@angular/core';
import { IframeCotacoesService } from './iframe-cotacoes.service';

@Component({
  selector: 'app-iframe-cotacoes',
  templateUrl: './iframe-cotacoes.component.html',
  styleUrls: ['./iframe-cotacoes.component.scss'],
})
export class IframeCotacoesComponent {
  cota: string;

  cards = [0, 0, 0].map((res) => {
    const menu = [
      {
        title: 'Preço Mínimo',
        value: 0,
      },
      {
        title: 'Preço Médio',
        value: 0,
      },
      {
        title: 'Preço Máximo',
        value: 0,
      },
    ];

    return menu;
  });

  options = [
    this.nomeIdem('Alho'),
    this.nomeIdem('Leite'),
    this.nomeIdem('Suino'),
    this.nomeIdem('Milho'),
    this.nomeIdem('Sorgo'),
    this.nomeIdem('Latex'),
    this.nomeIdem('Suino'),
    this.nomeIdem('Frango'),
    this.nomeIdem('Cavaco'),
    this.nomeIdem('Tilapia'),
    this.nomeIdem('Melancia'),
    this.nomeIdem('Eucalipto'),
    this.nomeIdem('Soja Futuro'),
    this.nomeIdem('Batata Lisa'),
    this.nomeIdem('Vaca a Vista'),
    this.nomeIdem('Milho Futuro'),
    this.nomeIdem('Farelo de Soja'),
    {
      op: 'Feijão',
      desc_cotacao: 'feijao',
    },
    {
      op: 'Boi Gordo - À vista',
      desc_cotacao: 'boi-gordo',
    },
    {
      op: 'Vaca Gorda - À vista',
      desc_cotacao: 'vaca-gorda',
    },
    {
      op: 'Cebola',
      desc_cotacao: 'cebola-nacional',
    },
    {
      op: 'Soja',
      desc_cotacao: 'soja-disponivel',
    },
    {
      op: 'Soja Balcão',
      desc_cotacao: 'soja-balcao',
    },
    {
      op: 'Banana',
      desc_cotacao: 'banana-prata',
    },
    {
      op: 'Laranja Pera Rio',
      desc_cotacao: 'laranja-pera-rio',
    },
  ];

  nomeIdem(nome: string) {
    if (!nome.includes(' ')) {
      return {
        op: nome,
        desc_cotacao: nome.toLowerCase(),
      };
    } else if (nome.includes(' ')) {
      const set = nome.toLowerCase().split(' ');

      return {
        op: nome,
        desc_cotacao: set.join('-'),
      };
    }
  }

  chartOptions = {
    series: [
      {
        name: 'Média',
        data: [],
      },
    ],
    chart: {
      toolbar: {
        show: false,
      },
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [],
    },
    yaxis: {
      labels: {
        formatter: (value) => {
          return money(value);
        },
      },
    },
    colors: ['#5ba74d'],
    tooltip: {
      y: {
        formatter: (value) => {
          return money(value);
        },
      },
    },
  };

  cotacoes: any;
  selected = null;
  grafico: boolean;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private iframeService: IframeCotacoesService
  ) {}

  ngOnInit() {
    this.iframeService.cotacoes$.pipe(first()).subscribe((res) => {
      this.cotacoes = res;
    });

    this.iframeService.modo$.pipe(first()).subscribe((res) => {
      this.grafico = res === 'grafico';
    });

    this.iframeService.cota$.subscribe((res) => {
      this.cota = res;
    });

    this.days();
  }

  returnNome(index?: number) {
    let x: any;

    if (!index && index !== 0) {
      x = this.options.find((res) => res?.desc_cotacao === this.cota);
    } else {
      x = this.options.find((res) => res?.desc_cotacao === this.cota[index]);
    }

    return x?.op;
  }

  days() {
    if (!this.grafico) {
      this.cotacoes.map((res, index) => {
        const cotacoes = res.slice(0, 60);

        const agrupando = cotacoes.reduce((acc, curr) => {
          const { date, value } = curr;

          const valor = Number(value.split('.').join('').replace(',', '.'));

          if (!acc[date]) {
            acc[date] = { date, soma: 0, contador: 0 };
          }

          acc[date].soma += valor;

          acc[date].contador++;

          return acc;
        }, {});

        const mediaDoGrupo = Object.values(agrupando).map((item: any) => ({
          date: item.date,
          value: item.soma / item.contador,
        }));

        const tratadas = mediaDoGrupo.slice(0, 2);

        const valores = tratadas.map((cot) => cot.value);

        this.cards[index][0].value = Math.min.apply(null, valores);

        this.cards[index][1].value = valores.length
          ? valores.reduce((accumulator, curr) => accumulator + curr) /
            valores.length
          : 0;

        this.cards[index][2].value = Math.max.apply(null, valores);
      });

      return this.changeDetectorRef.markForCheck();
    }

    const array = [];

    for (const [index, cotacao] of this.cotacoes.entries()) {
      if (index) {
        if (array[array.length - 1].date !== cotacao.date) {
          array.push({
            date: cotacao.date,
            value: Number(cotacao.value.split('.').join('').replace(',', '.')),

            length: 1,
          });
        } else {
          array[array.length - 1].value += Number(
            cotacao.value.split('.').join('').replace(',', '.')
          );

          array[array.length - 1].length += 1;
        }
      } else {
        array.push({
          date: cotacao.date,
          value: Number(cotacao.value.split('.').join('').replace(',', '.')),
          length: 1,
        });
      }
    }

    this.chartOptions.xaxis.categories = [];

    this.chartOptions.series[0].data = [];

    let idx = 0;

    while (idx < array.length && moment(array[idx].date).isAfter(moment())) {
      idx++;
    }

    for (const e of array.splice(idx, 10).reverse()) {
      this.chartOptions.xaxis.categories.push(
        moment(e.date).format('DD/MM/YYYY')
      );

      this.chartOptions.series[0].data.push((e.value / e.length).toFixed(2));
    }

    this.changeDetectorRef.markForCheck();
  }
}

// =============== funcoes auxiliares ===================

export const money = (value) => {
  if (!value) {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(0);
  }

  const v = checkDot(value);

  const res = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(v);

  return res;
};

export const checkDot = (valor) => {
  const v = valor.toString().split('.');

  if (v.length > 2) {
    const value = valor.toString().split('.').join('');

    const res = parseFloat(value) / 100;

    return res;
  } else {
    const res = parseFloat(valor);

    return res;
  }
};
