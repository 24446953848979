import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';

import { ChatService } from './chat.service';
import { Chat, Contact, Profile } from './chat.types';

@Injectable({
  providedIn: 'root'
})
export class ChatChatsResolver implements Resolve<any>
{
  /**
   * Constructor
   */
  constructor(
    private _chatService: ChatService,
    private _router: Router
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Chat[]> | any {
    return this._chatService.getChats();
  }
}

@Injectable({
  providedIn: 'root'
})
export class ChatChatResolver implements Resolve<any>
{
  /**
   * Constructor
   */
  constructor(
    private _chatService: ChatService,
    private _router: Router
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Chat> {
    return this._chatService.getChatById(route.paramMap.get('id'))
      .pipe(
        // Error here means the requested chat is not available
        catchError((error) => {

          // Log the error
          console.error(error);

          // Get the parent url
          const parentUrl = state.url.split('/').slice(0, -1).join('/');

          // Navigate to there
          this._router.navigateByUrl(parentUrl);

          // Throw an error
          return throwError(error);
        })
      );
  }
}

@Injectable({
  providedIn: 'root'
})
export class ChatContactsResolver implements Resolve<any>
{
  /**
   * Constructor
   */
  constructor(
    private _chatService: ChatService,
    private _router: Router
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Contact[]> | any {
    return this._chatService.getContacts();
  }
}

@Injectable({
  providedIn: 'root'
})
export class ChatProfileResolver implements Resolve<any>
{
  /**
   * Constructor
   */
  constructor(
    private _chatService: ChatService,
    private _router: Router
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Profile> | any {
    return this._chatService.getProfile();
  }
}

@Injectable({
  providedIn: 'root'
})
export class ConversaResolver implements Resolve<any>
{
  /**
   * Constructor
   */
  constructor(
    private _chatService: ChatService,
    private _router: Router
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Profile> | any {
    return this._chatService.getConversa();
  }
}

@Injectable({
  providedIn: 'root'
})
export class ConversaMensagemClasseResolver implements Resolve<any>
{
  /**
   * Constructor
   */
  constructor(
    private _chatService: ChatService,
    private _router: Router
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Profile> | any {
    return this._chatService.getConversaMensagemClasse();
  }
}

@Injectable({
  providedIn: 'root'
})
export class NovasMensagensResolver implements Resolve<any>
{
  /**
   * Constructor
   */
  constructor(
    private _chatService: ChatService,
    private _router: Router
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Profile> | any {
    return this._chatService.getNovasMensagens();
  }
}

@Injectable({
  providedIn: 'root'
})
export class CampanhaResolver implements Resolve<any>
{
  /**
   * Constructor
   */
  constructor(
    private _chatService: ChatService,
    private _router: Router
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Profile> | any {
    return this._chatService.getCampanha();
  }
}

@Injectable({
  providedIn: 'root'
})
export class MensagensResolver implements Resolve<any>
{
  /**
   * Constructor
   */
  constructor(
    private _chatService: ChatService,
    private _router: Router
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Profile> | any {
    this._chatService.setId(route.paramMap.get('id'));
    return this._chatService.getMensagens(route.paramMap.get('id'));
  }
}

@Injectable({
  providedIn: 'root'
})
export class EtiquetasResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(
    private _chatService: ChatService,
    private _router: Router
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolver
   *
   * @param route
   * @param state
   */
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Profile> | any {
    return this._chatService.getEtiquetas();
  }
}
