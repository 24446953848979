export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: 'AIzaSyCnazOXqppjEvkayA7Rq7CameBHTA8z4FE',
    authDomain: 'infosindical-sistemafaeg.firebaseapp.com',
    projectId: 'infosindical-sistemafaeg',
    storageBucket: 'infosindical-sistemafaeg.appspot.com',
    messagingSenderId: '246711004127',
    appId: '1:246711004127:web:fca8c43260248a1baf726a',
    measurementId: 'G-DG6NTJV0MW',
  },
  api: 'https://api.sicoob.campotech.com.br/rest',
  searchApi: 'https://apitv.campolab.com.br/',
  apiLeiteBem: 'https://api.leitebem.com.br',
  oauthLeiteBem: 'https://id.sistemafaeg.org.br',
  version: 'v3.7.7',
};
