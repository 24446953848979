import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IsActiveMatchOptions } from '@angular/router';
import { AlertComponent } from 'app/modules/alert/alert.component';
import { first, lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FuseUtilsService {
  /**
   * Constructor
   */
  constructor(
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    @Inject(DOCUMENT) private document: Document
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the equivalent "IsActiveMatchOptions" options for "exact = true".
   */
  get exactMatchOptions(): IsActiveMatchOptions {
    return {
      paths: 'exact',
      fragment: 'ignored',
      matrixParams: 'ignored',
      queryParams: 'exact',
    };
  }

  /**
   * Get the equivalent "IsActiveMatchOptions" options for "exact = false".
   */
  get subsetMatchOptions(): IsActiveMatchOptions {
    return {
      paths: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored',
      queryParams: 'subset',
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Generates a random id
   *
   * @param length
   */
  randomId(length: number = 10): string {
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let name = '';

    for (let i = 0; i < 10; i++) {
      name += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    return name;
  }

  verificarClasseEstilo(classe: string): boolean {
    const styleElements = this.document.getElementsByTagName('style');

    for (let i = 0; i < styleElements.length; i++) {
      const styles = styleElements[i].innerHTML;
      const valid = styles?.replace('styles:', '');

      if (valid?.trim()?.startsWith('.' + classe)) {
        return false;
      }
    }

    return true;
  }
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  async foto(file: any, nome: any, pasta: string) {
    const novaFoto = new File([file], `${nome}.${file.name.split('.').pop()}`, {
      type: file.type,
    });

    const foto: FormData = new FormData();

    const path = `fotos/${pasta}/${nome}.${file.name.split('.').pop()}`;

    const src = `https://faeg-cdn.nyc3.digitaloceanspaces.com/faegDigital/${path}`;

    foto.append('upload', novaFoto, src);

    const response = await lastValueFrom(this.upload(foto, path));

    return response;
  }

  upload(document: any, local: string) {
    return this.http
      .post(
        `https://api.talentosdocampo.com.br/usuarios/upload?pasta=faegDigital&local=${local}`,
        document
      )
      .pipe(first());
  }

  updateBody(form: any, db: any) {
    let valid = 0;

    const body: any = db;

    for (const dado in form) {
      if (JSON.stringify(form[dado]) !== JSON.stringify(db[dado])) {
        body[dado] = form[dado];

        valid++;
      }
    }

    if (valid > 0) {
      return body;
    } else {
      return null;
    }
  }

  toast(
    title: string,
    message: string,
    type: 'success' | 'error' | 'warning' | 'info',
    showIcon: boolean,
    duration: number
  ) {
    let panelClass;
    switch (type) {
      case 'success':
        panelClass = ['bg-green-500', 'text-white'];
        break;
      case 'error':
        panelClass = ['bg-red-500', 'text-white'];
        break;
      case 'warning':
        panelClass = ['bg-yellow-400', 'text-white'];
        break;
      case 'info':
        panelClass = ['bg-blue-400', 'text-white'];
        break;
    }

    this._snackBar.openFromComponent(AlertComponent, {
      duration,
      panelClass,
      data: {
        title,
        message,
        type,
        showIcon,
      },
      horizontalPosition: 'end',
      verticalPosition: 'top',
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  // Funcoes Principais |||

  valida_cpf_cnpj(valor: any) {
    let valida = this.CpfouCnpj(valor);
    valor = valor.toString();
    valor = valor.replace(/[^0-9]/g, '');

    if (valida === 'CPF') {
      return this.ValidarCpf(valor);
    } else if (valida === 'CNPJ') {
      return this.ValidarCnpj(valor);
    } else {
      return false;
    }
  }

  // Funcoes Secundarias |||

  CpfouCnpj(valor: any) {
    valor = valor.toString();
    valor = valor.replace(/[^0-9]/g, '');

    if (valor.length === 11) {
      return 'CPF';
    } else if (valor.length === 14) {
      return 'CNPJ';
    } else {
      return false;
    }
  }

  CalcularDigitos(digitos: any, posicoes = 10, soma_digitos = 0) {
    digitos = digitos.toString();

    for (let i = 0; i < digitos.length; i++) {
      soma_digitos = soma_digitos + digitos[i] * posicoes;
      posicoes--;
      if (posicoes < 2) {
        posicoes = 9;
      }
    }

    soma_digitos = soma_digitos % 11;

    if (soma_digitos < 2) {
      soma_digitos = 0;
    } else {
      soma_digitos = 11 - soma_digitos;
    }

    let cpf = digitos + soma_digitos;

    return cpf;
  }

  ValidarCpf(valor: any) {
    if (
      valor == '00000000000' ||
      valor == '11111111111' ||
      valor == '22222222222' ||
      valor == '33333333333' ||
      valor == '44444444444' ||
      valor == '55555555555' ||
      valor == '66666666666' ||
      valor == '77777777777' ||
      valor == '88888888888' ||
      valor == '99999999999'
    ) {
      return false;
    }

    valor = valor.toString();
    valor = valor.replace(/[^0-9]/g, '');

    let digitos = valor.substr(0, 9);

    let novo_cpf = this.CalcularDigitos(digitos);
    novo_cpf = this.CalcularDigitos(novo_cpf, 11);

    if (novo_cpf === valor) {
      return true;
    } else {
      return false;
    }
  }

  ValidarCnpj(valor: any) {
    valor = valor.toString();

    valor = valor.replace(/[^0-9]/g, '');

    let cnpj_original = valor;

    let primeiros_numeros_cnpj = valor.substr(0, 12);

    let primeiro_calculo = this.CalcularDigitos(primeiros_numeros_cnpj, 5);

    let segundo_calculo = this.CalcularDigitos(primeiro_calculo, 6);

    let cnpj = segundo_calculo;

    if (cnpj === cnpj_original) {
      return true;
    }
    return false;
  }
}
