/* eslint-disable */
export const categories = [
    {
        id   : '9a67dff7-3c38-4052-a335-0cef93438ff6',
        title: 'Web',
        slug : 'web'
    },
    {
        id   : 'a89672f5-e00d-4be4-9194-cb9d29f82165',
        title: 'Firebase',
        slug : 'firebase'
    },
    {
        id   : '02f42092-bb23-4552-9ddb-cfdcc235d48f',
        title: 'Cloud',
        slug : 'cloud'
    },
    {
        id   : '5648a630-979f-4403-8c41-fc9790dea8cd',
        title: 'Android',
        slug : 'android'
    }
];
export const courses = [
    {
        id         : '694e4e5f-f25f-470b-bd0e-26b1d4f64028',
        title      : 'Basics of Angular',
        slug       : 'basics-of-angular',
        description: 'Introductory course for Angular and framework basics',
        category   : 'web',
        duration   : 30,
        totalSteps : 11,
        updatedAt  : 'Jun 28, 2021',
        featured   : true,
        progress   : {
            currentStep: 1,
            completed  : 0
        }
    }
];
export const demoCourseContent = `

`;

export const demoCourseSteps = [
    {
        order   : 0,
        title   : 'SINDICATO RURAL DE ACREUNA',
        subtitle: 'Introducing the library and how it works',
        content : `<h2 class="text-2xl sm:text-3xl">SINDICATO RURAL DE ACREUNA</h1> ${demoCourseContent}`
    },
    {
        order   : 1,
        title   : 'Get the sample code',
        subtitle: 'Where to find the sample code and how to access it',
        content : `<h2 class="text-2xl sm:text-3xl">SINDICATO RURAL DE ACREUNA</h1> ${demoCourseContent}`
    },
    {
        order   : 2,
        title   : 'Create a Firebase project and Set up your app',
        subtitle: 'How to create a basic Firebase project and how to run it locally',
        content : `<h2 class="text-2xl sm:text-3xl">SINDICATO RURAL DE ACREUNA</h1> ${demoCourseContent}`
    },
    {
        order   : 3,
        title   : 'Install the Firebase Command Line Interface',
        subtitle: 'Setting up the Firebase CLI to access command line tools',
        content : `<h2 class="text-2xl sm:text-3xl">SINDICATO RURAL DE ACREUNA</h1> ${demoCourseContent}`
    }
];
