<fuse-loading-bar></fuse-loading-bar>

<fuse-vertical-navigation
  class="dark bg-primary-900 print:hidden pb-10"
  [appearance]="isScreenSmall ? 'default' : 'compact'"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation?.default"
  [menuHome]="menus"
  [opened]="!blocked && !isScreenSmall"
>
</fuse-vertical-navigation>

<div class="flex flex-col flex-auto w-full min-w-0">
  <div
    class="relative flex flex-0 items-center w-full h-20 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden"
  >
    <button
      class="w-20"
      mat-icon-button
      (click)="toggleNavigation('mainNavigation')"
    >
      <img class="w-full iconDominio" />
    </button>

    <app-select-sindicato
      class="w-full"
      *ngIf="!compara() && user"
    ></app-select-sindicato>

    <div *ngIf="!isScreenSmall && isHome" class="ml-8 flex flex-row gap-3">
      <div *ngFor="let dado of menus">
        <button
          *ngIf="dado?.rota"
          [routerLink]="dado?.rota"
          mat-stroked-button
          color="primary"
        >
          <mat-icon [svgIcon]="dado.icon"></mat-icon>
          {{ dado.nome }}
        </button>

        <button
          color="primary"
          mat-stroked-button
          *ngIf="dado?.funcao"
          (click)="dado?.funcao ? scrollTo(dado?.id) : ''"
        >
          <mat-icon [svgIcon]="dado.icon"></mat-icon>
          {{ dado.nome }}
        </button>
      </div>
    </div>

    <div *ngIf="!isScreenSmall && isLeiteBem" class="ml-8 flex flex-row gap-3">
      <div *ngFor="let dado of menuLeitebem">
        <button
          *ngIf="dado?.rota"
          [routerLink]="dado?.rota"
          mat-stroked-button
          color="primary"
        >
          <mat-icon [svgIcon]="dado.icon"></mat-icon>
          {{ dado.nome }}
        </button>

        <button
          color="primary"
          mat-stroked-button
          *ngIf="dado?.funcao"
          (click)="dado?.funcao ? scrollTo(dado?.id) : ''"
        >
          <mat-icon [svgIcon]="dado.icon"></mat-icon>
          {{ dado.nome }}
        </button>
      </div>

      <div>
        <button
          [matMenuTriggerFor]="userActions"
          mat-stroked-button
          color="primary"
        >
          <mat-icon [svgIcon]="'iconsmind:box_open'"></mat-icon>
          Estoque
        </button>

        <button
          color="primary"
          mat-stroked-button
          *ngIf="dado?.funcao"
          (click)="dado?.funcao ? scrollTo(dado?.id) : ''"
        >
          <mat-icon [svgIcon]="dado.icon"></mat-icon>
          {{ dado.nome }}
        </button>
      </div>
    </div>

    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
      <!-- <fuse-fullscreen class="hidden md:block"></fuse-fullscreen> -->
      <!-- <search [appearance]="'bar'"></search> -->
      <notifications *ngIf="user"></notifications>
      <button class="lg:hidden" mat-icon-button>
        <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
      </button>
      <user *ngIf="user"></user>
    </div>
  </div>

  <div class="flex flex-col flex-auto">
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <div
    *ngIf="!hidden && !isMapa"
    class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden"
  >
    <span class="font-medium text-secondary"
      ><span class="tituloTotal"></span> &copy; {{ currentYear }}</span
    >
  </div>
</div>

<!-- Quick chat -->
<!--<quick-chat #quickChat="quickChat" *ngIf="hidden"></quick-chat>-->

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <button mat-menu-item [routerLink]="'/admin/leite-bem/estoque/componentes'">
    <span class="flex flex-col leading-none">
      <span>Componentes</span>
    </span>
  </button>
  <mat-divider class="my-2"></mat-divider>
  <button mat-menu-item [routerLink]="'/admin/leite-bem/estoque/kits-produtor'">
    <span>Kit Produtor</span>
  </button>
</mat-menu>
