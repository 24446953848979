import { Conversa } from './conversa';


export class ConversaMensagem {

  id: number;
  de: String;
  para: String;
  conversa: Conversa;
  mensagem: String;
  data: Date;
  nome: String;
  entregue: boolean;
  recebida: boolean;
  lida: boolean;
  sid: String;
  resposta: boolean;
  midia: string;
  conversaMidiaId: number;
  conversaMidiaHash: string;
  mimetypeMidia: string;

  constructor(conversaMensagem?: ConversaMensagem) {
    if (conversaMensagem) {
      this.id = conversaMensagem.id;
      this.de = conversaMensagem.de;
      this.para = conversaMensagem.para;
      this.conversa = new Conversa(conversaMensagem.conversa);
      this.mensagem = conversaMensagem.mensagem;
      this.data = conversaMensagem.data ? new Date(conversaMensagem.data.toString().replace(/-/g, '\/').replace(/T.+/, '')) : null;
      this.nome = conversaMensagem.nome;
      this.entregue = conversaMensagem.entregue;
      this.recebida = conversaMensagem.recebida;
      this.lida = conversaMensagem.lida;
      this.resposta = conversaMensagem.resposta;
      this.midia = conversaMensagem.midia;
      this.mimetypeMidia = conversaMensagem.mimetypeMidia;
      this.conversaMidiaId = conversaMensagem.conversaMidiaId;
      this.conversaMidiaHash = conversaMensagem.conversaMidiaHash;
    } else {
      this.id = null;
      this.de = '';
      this.para = '';
      this.conversa = null;
      this.mensagem = null;
      this.data = null;
      this.nome = '';
      this.entregue = false;
      this.recebida = false;
      this.lida = false;
      this.resposta = false;
      this.midia = null;
      this.mimetypeMidia = null;
      this.conversaMidiaId = null;
      this.conversaMidiaHash = null;
    }
  }

}
