import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, Observable, tap } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppService { 
  constructor(private _httpClient: HttpClient) {}

  getCamapnhaIds(): Observable<any> {
    return this._httpClient
      .get(`${environment.api}/campanhaUsuario/obterCampanhaUsuarioIdsCampanha`)
      .pipe(
        catchError((error) => {
          throw error;
        }),
        tap((response: any) => {
          localStorage.setItem(
            'campanhaUsuarioIdsCampanha',
            JSON.stringify(response)
          );
        })
      );
  }
}
