import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  filter,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
  throwError,
  takeUntil,
  Subject,
  catchError,
} from 'rxjs';
import * as moment from 'moment';

import { environment } from 'environments/environment';
import { Chat, Contact, Profile } from './chat.types';
import { Tag } from '../../contacts/contacts.types';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private _chat: BehaviorSubject<Chat> = new BehaviorSubject(null);
  private _chats: BehaviorSubject<Chat[]> = new BehaviorSubject(null);
  private _contact: BehaviorSubject<Contact> = new BehaviorSubject(null);
  private _contacts: BehaviorSubject<Contact[]> = new BehaviorSubject(null);
  private _profile: BehaviorSubject<Profile> = new BehaviorSubject(null);

  private _conversa: BehaviorSubject<any> = new BehaviorSubject(null);
  private _conversaMensagemClasse: BehaviorSubject<any> = new BehaviorSubject(
    null
  );
  private _novasMensagens: BehaviorSubject<any> = new BehaviorSubject(null);
  private _campanha: BehaviorSubject<any> = new BehaviorSubject(null);
  private _id: BehaviorSubject<any> = new BehaviorSubject(null);
  private _mensagens: BehaviorSubject<any> = new BehaviorSubject(null);
  private _ultimoContato: BehaviorSubject<any> = new BehaviorSubject(null);
  private _etiquetas: BehaviorSubject<Tag[] | null> = new BehaviorSubject([]);

  public alteraStatusAtendimento: BehaviorSubject<any> = new BehaviorSubject(
    false
  );

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for chat
   */
  get chat$(): Observable<Chat> {
    return this._chat.asObservable();
  }

  /**
   * Getter for chats
   */
  get chats$(): Observable<Chat[]> {
    return this._chats.asObservable();
  }

  /**
   * Getter for contact
   */
  get contact$(): Observable<Contact> {
    return this._contact.asObservable();
  }

  /**
   * Getter for contacts
   */
  get contacts$(): Observable<Contact[]> {
    return this._contacts.asObservable();
  }

  /**
   * Getter for profile
   */
  get profile$(): Observable<Profile> {
    return this._profile.asObservable();
  }

  /**
   * Getter for profile
   */
  get conversa$(): Observable<any> {
    return this._conversa.asObservable();
  }

  get etiquetas$(): Observable<any> {
    return this._etiquetas.asObservable();
  }

  /**
   * Setter for profile
   */
  setConversa(conversa) {
    this._conversa.next(conversa);
  }

  /**
   * Getter for profile
   */
  get conversaMensagemClasse$(): Observable<any> {
    return this._conversaMensagemClasse.asObservable();
  }

  /**
   * Getter for profile
   */
  get novasMensagens$(): Observable<any> {
    return this._novasMensagens.asObservable();
  }

  /**
   * Getter for profile
   */
  get campanha$(): Observable<any> {
    return this._campanha.asObservable();
  }

  /**
   * Getter for profile
   */
  get id$(): Observable<any> {
    return this._id.asObservable();
  }

  /**
   * Getter for profile
   */
  get mensagens$(): Observable<any> {
    return this._mensagens.asObservable();
  }

  /**
   * Getter for profile
   */
  get ultimoContato$(): Observable<any> {
    return this._ultimoContato.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get chats
   */
  getChats(): Observable<any> {
    return this._httpClient.get<Chat[]>('api/apps/chat/chats').pipe(
      tap((response: Chat[]) => {
        this._chats.next(response);
      })
    );
  }

  /**
   * Get contact
   *
   * @param id
   */
  getContact(id: string): Observable<any> {
    return this._httpClient
      .get<Contact>('api/apps/chat/contacts', { params: { id } })
      .pipe(
        tap((response: Contact) => {
          this._contact.next(response);
        })
      );
  }

  /**
   * Get contacts
   */
  getContacts(): Observable<any> {
    return this._httpClient.get<Contact[]>('api/apps/chat/contacts').pipe(
      tap((response: Contact[]) => {
        this._contacts.next(response);
      })
    );
  }

  /**
   * Get profile
   */
  getProfile(): Observable<any> {
    return this._httpClient.get<Profile>('api/apps/chat/profile').pipe(
      tap((response: Profile) => {
        this._profile.next(response);
      })
    );
  }

  /**
   * Get chat
   *
   * @param id
   */
  getChatById(id: string): Observable<any> {
    return this._httpClient
      .get<Chat>('api/apps/chat/chat', { params: { id } })
      .pipe(
        map((chat) => {
          // Update the chat
          this._chat.next(chat);

          // Return the chat
          return chat;
        }),
        switchMap((chat) => {
          if (!chat) {
            return throwError('Could not found chat with id of ' + id + '!');
          }

          return of(chat);
        })
      );
  }

  /**
   * Update chat
   *
   * @param id
   * @param chat
   */
  updateChat(id: string, chat: Chat): Observable<Chat> {
    return this.chats$.pipe(
      take(1),
      switchMap((chats) =>
        this._httpClient
          .patch<Chat>('api/apps/chat/chat', {
            id,
            chat,
          })
          .pipe(
            map((updatedChat) => {
              // Find the index of the updated chat
              const index = chats.findIndex((item) => item.id === id);

              // Update the chat
              chats[index] = updatedChat;

              // Update the chats
              this._chats.next(chats);

              // Return the updated contact
              return updatedChat;
            }),
            switchMap((updatedChat) =>
              this.chat$.pipe(
                take(1),
                filter((item) => item && item.id === id),
                tap(() => {
                  // Update the chat if it's selected
                  this._chat.next(updatedChat);

                  // Return the updated chat
                  return updatedChat;
                })
              )
            )
          )
      )
    );
  }

  /**
   * Reset the selected chat
   */
  resetChat(): void {
    this._chat.next(null);
    this._mensagens.next(null);
    this._id.next(null);
  }

  /**
   * Get chats
   */
  getConversa(data = null, preserv = false): Observable<any> {
    if (data) {
      return this._httpClient
        .post<any>(`${environment.api}/conversa/filter`, data)
        .pipe(
          tap((response: any) => {
            let conversa = [];
            this.conversa$
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((c) => {
                conversa = c && c.length ? c : [];
              });
            if (preserv) {
              this._conversa.next([...conversa, ...response]);
            } else {
              this._conversa.next(response);
            }
          })
        );
    } else {
      return this._httpClient
        .post<any>(`${environment.api}/conversa/filter`, {
          date: moment().format('YYYY-MM-DD'),
          pessoaId: null,
          campanhaId: null,
          telefone: null,
          responder: true,
          ativas: true,
          inativas: true,
          order: 'responder',
        })
        .pipe(
          tap((response: any) => {
            let conversa = [];
            this.conversa$
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((c) => {
                conversa = c && c.length ? c : [];
              });
            if (preserv) {
              this._conversa.next([...conversa, ...response]);
            } else {
              this._conversa.next(response);
            }
          })
        );
    }
  }

  /**
   * Get chats
   */
  getConversaMensagemClasse(): Observable<any> {
    return this._httpClient
      .get<any>(`${environment.api}/conversaMensagemClasse`)
      .pipe(
        tap((response: any) => {
          this._conversaMensagemClasse.next(response);
        })
      );
  }

  /**
   * Get chats
   */
  getNovasMensagens(): Observable<any> {
    return this._httpClient
      .get<any>(`${environment.api}/conversa/novasMensagens`)
      .pipe(
        tap((response: any) => {
          this._novasMensagens.next(response);
        })
      );
  }

  /**
   * Get chats
   */
  getCampanha(): Observable<any> {
    return this._httpClient.get<any>(`${environment.api}/campanha`).pipe(
      tap((response: any) => {
        this._campanha.next(response);
      })
    );
  }

  /**
   * Get chats
   */
  setId(id) {
    this._id.next(id);
  }

  /**
   * Get chats
   */
  getMensagens(id): Observable<any> {
    return this._httpClient
      .get<any>(`${environment.api}/conversa/mensagens/${id}`)
      .pipe(
        tap((response: any) => {
          this._mensagens.next({
            id,
            messages: response,
          });
        })
      );
  }

  novasMensagens(): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(`${environment.api}/conversa/novasMensagens`)
        .subscribe(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  setMensagensLidas(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(`${environment.api}/conversa/novasMensagens`, data)
        .subscribe(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  pessoas(text): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(`${environment.api}/pessoa/complete/${text}`)
        .subscribe(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  conversa(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(`${environment.api}/conversa/filter`, {
          pessoaId: id,
        })
        .subscribe(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  ultimoContato(ultimoContato) {
    this._ultimoContato.next(ultimoContato);
  }

  enviarMensagem(id, message): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(`${environment.api}/conversa/enviarMensagem/${id}`, message)
        .subscribe(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  salvarContato(data): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .post(`${environment.api}/conversaMensagemClasse`, data)
        .subscribe(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  getCampanhas(nome: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (nome.trim() === '') {
        return resolve([]);
      }
      this._httpClient
        .get(`${environment.api}/campanha/complete/${nome}`)
        .subscribe(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  validarMidiaExiste(hash: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this._httpClient
        .get(`${environment.api}/conversa/mediabyhash/${hash}`)
        .subscribe(
          (res) => resolve(res),
          (err) => reject(err)
        );
    });
  }

  setAtendente(body) {
    return this._httpClient.post(`${environment.api}/conversa/atendente`, body);
  }

  saveMidia(body) {
    return this._httpClient.post(
      `${environment.api}/automacao/saveMidia`,
      body
    );
  }

  removerAtendente(idConversa): Observable<any> {
    return this._httpClient
      .delete(`${environment.api}/conversa/removerAtendente/${idConversa}`)
      .pipe(
        catchError((error) => {
          throw error;
        }),
        tap({})
      );
  }

  getEtiquetas(): Observable<any> {
    return this._httpClient.get(`${environment.api}/etiqueta/listAll`).pipe(
      catchError((error) => {
        throw error;
      }),
      tap((response: any) => {
        this._etiquetas.next(response);
      })
    );
  }
}
