import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import { forkJoin, Observable } from 'rxjs';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { ProjectService } from 'app/modules/dashboards/project/project.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { AuthService } from './core/auth/auth.service';
import { SelectSindicatoService } from './shared/select-sindicato/select-sindicato.service';

@Injectable({
  providedIn: 'root',
})
export class InitialDataResolver implements Resolve<any> {
  /**
   * Constructor
   */
  constructor(
    private _auth: AuthService,
    private _appService: AppService,
    private _projectService: ProjectService,
    private _sindicatos: SelectSindicatoService,
    private _navigationService: NavigationService,
    private _notificationsService: NotificationsService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Use this resolver to resolve initial mock-api for the application
   *
   * @param route
   * @param state
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    const resolvers = [this._appService.getCamapnhaIds()];

    const login = this._auth?.userValue?.login;

    if (login) {
      resolvers.push(this._sindicatos.getSindicatos());
      resolvers.push(this._notificationsService.getAll(login));
      resolvers.push(this._projectService.getAtendimentosOpen(login));
    }

    return forkJoin(resolvers);
  }
}
