import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, tap } from 'rxjs';
import { Navigation } from 'app/core/navigation/navigation.types';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private _navigation: ReplaySubject<Navigation> =
    new ReplaySubject<Navigation>(1);
  private _hidden: ReplaySubject<Boolean> = new ReplaySubject<Boolean>(null);

  /**
   * Constructor
   */
  constructor() {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for navigation
   */
  get navigation$(): Observable<Navigation> {
    return this._navigation.asObservable();
  }

  get hidden$(): Observable<Boolean> {
    return this._hidden.asObservable();
  }

  set hidden(value: any) {
    this._hidden.next(value);
  }

  set navigation(value: any) {
    this._navigation.next(value);
  }
}
