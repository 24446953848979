import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import {
  BehaviorSubject,
  Observable,
  catchError,
  combineLatest,
  distinctUntilChanged,
  map,
  of,
  tap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SelectSindicatoService {
  private _sindicatos: BehaviorSubject<any> = new BehaviorSubject(null);
  private _sindicatoSelecionado: BehaviorSubject<any> = new BehaviorSubject(
    null
  );
  private _municipios: BehaviorSubject<any> = new BehaviorSubject(null);
  private _municipiosSelecionado: BehaviorSubject<any> = new BehaviorSubject(
    null
  );

  constructor(private _httpClient: HttpClient) {}

  get sindicatos$(): Observable<any> {
    return this._sindicatos.asObservable();
  }

  get sindicatos() {
    return this._sindicatos.getValue();
  }

  set sindicatos(value: any) {
    this._sindicatos.next(value);
  }

  // =======================================

  get sindicatoSelecionado$(): Observable<any> {
    return this._sindicatoSelecionado.asObservable();
  }

  get sindicatoSelecionado() {
    return this._sindicatoSelecionado.getValue();
  }

  set sindicatoSelecionado(value: any) {
    this._sindicatoSelecionado.next(value);
  }

  // ==================================================

  get municipios$(): Observable<any> {
    return this._municipios.asObservable();
  }

  get municipios() {
    return this._municipios.getValue();
  }

  set municipios(value: any) {
    this._municipios.next(value);
  }

  // =======================================

  get municipiosSelecionado$(): Observable<any> {
    return this._municipiosSelecionado.asObservable();
  }

  get municipiosSelecionado() {
    return this._municipiosSelecionado.getValue();
  }

  set municipiosSelecionado(value: any) {
    this._municipiosSelecionado.next(value);
  }

  // =======================================================================

  loading = false;
  getSindicatos(): Observable<any> {
    if (this.loading) {
      console.log('jaSindicato');
      return of(true);
    } else {
      this.loading = true;

      return this._httpClient
        .get(`${environment.api}/usuario/getSindicatos`)
        .pipe(
          catchError((error) => {
            throw error;
          }),
          tap((response: any) => {
            this._sindicatos.next(response);
          })
        );
    }
  }

  getMunicipio(id): Observable<any> {
    if (id) {
      return this._httpClient.get(`${environment.api}/municipio/${id}`).pipe(
        catchError((error) => {
          return of(this._municipios.next([]));
        }),
        tap((response: any) => {
          this._municipios.next(response);
        })
      );
    } else {
      return this._httpClient.get(`${environment.api}/municipio`).pipe(
        catchError((error) => {
          return of(this._municipios.next([]));
        }),
        tap((response: any) => {
          this._municipios.next(response);
        })
      );
    }
  }

  completeSindicatos(texto: string): Observable<any> {
    let searchQuery$: BehaviorSubject<string> = new BehaviorSubject(texto);

    return combineLatest([this.sindicatos$, searchQuery$]).pipe(
      distinctUntilChanged(),
      map(([sindicatos, searchQuery]) => {
        if (!sindicatos || !sindicatos?.length) {
          return;
        }

        let filtred = sindicatos;

        if (searchQuery) {
          searchQuery = searchQuery
            ?.normalize('NFD')
            ?.replace(/[\u0300-\u036f]/g, '');

          filtred = filtred.filter((sindicato) => {
            let check = [
              this.normalizar(sindicato?.nome),
              this.normalizar(sindicato?.municipio?.uf),
              this.normalizar(sindicato?.municipio?.nome),
              this.normalizar(sindicato?.municipio?.descricao),
            ];

            return check?.find((res) => res?.includes(searchQuery))?.length;
          });
        }

        return filtred?.slice(0, 30);
      })
    );
  }

  normalizar(texto: string) {
    return texto
      ?.normalize('NFD')
      ?.replace(/[\u0300-\u036f]/g, '')
      ?.toLocaleLowerCase();
  }
}
