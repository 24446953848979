import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { catchError, forkJoin, Observable, of, tap } from 'rxjs';
import { IframeCotacoesService } from './iframe-cotacoes.service';

@Injectable({
  providedIn: 'root',
})
export class IframeCotacoesResolver implements Resolve<any> {
  constructor(private readonly service: IframeCotacoesService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    if (route.params.modo === 'grafico') {
      return this.service.getCotacoes(route.params.modo, route.params.cota);
    } else {
      const cota = tipos[route.params.cota];

      const resolvers = [
        this.service.getCotacoes(route.params.modo, cota[0], true),
        this.service.getCotacoes(route.params.modo, cota[1], true),
        this.service.getCotacoes(route.params.modo, cota[2], true),
      ];

      return forkJoin(resolvers).pipe(
        catchError((err) => {
          console.log(err);
          return of([]);
        }),
        tap((res) => {
          this.service.cotacoes$ = res;
          this.service.cota$ = [cota[0], cota[1], cota[2]];

          return of(res);
        })
      );
    }
  }
}

const tipos = {
  silvicultura: ['eucalipto', 'cavaco', 'latex'],
  pecuariaOutros: ['boi-gordo', 'frango', 'suino'],
  avicultura: ['frango', 'milho', 'farelo-de-soja'],
  suinocultura: ['suino', 'milho', 'farelo-de-soja'],
  piscicultura: ['tilapia', 'milho', 'farelo-de-soja'],
  bovinoculturaCorte: ['boi-gordo', 'milho', 'vaca-gorda'],
  bovinoculturaLeite: ['leite', 'milho', 'farelo-de-soja'],
  olericultura: ['alho', 'batata-lisa', 'cebola-nacional'],
  agriculturaOutros: ['soja-disponivel', 'milho', 'feijao'],
  fruticultura: ['banana-prata', 'laranja-pera-rio', 'melancia'],
};
