import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MenusService {
  constructor() {}

  menus = [
    {
      id: 'home',
      nome: 'Home',
      icon: 'heroicons_outline:home',
      rota: 'home',
    },
    {
      id: 'solutions',
      nome: 'Soluções',
      icon: 'heroicons_outline:calculator',
      funcao: true,
    },
    {
      nome: 'Acesse Aqui',
      rota: 'dashboards',
      icon: 'heroicons_outline:desktop-computer',
    },




  ]

  menuLeitebem = [

    {
      nome: 'Leitebem',
      icon: 'dashboard',
      rota: '/admin/leite-bem',
    },
    {
      nome: 'Importar Animais',
      icon: 'iconsmind:cow',
      rota: '/admin/leite-bem/importar-animais'
    },
    {
      nome: 'Pesagens',
      icon: 'line_weight',
      rota: '/admin/leite-bem/pesagens'
    },
    {
      nome: 'Monitoramento',
      icon: 'iconsmind:monitoring',
      rota: '/admin/leite-bem/monitoramento'
    }
  ]
}
